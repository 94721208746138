<template>
  <div class>
    <div class="container">
      <div v-if="loading" class="loader">Loading...</div>
      <div class="welcome-flow-container" v-if="!loading">
        <div class="row d-flex justify-content-center align-items-center vh-100">
          <div class="col-12 col-lg-5 order-lg-3">
            <div class="widget mb-3">
              <div class="widget-splash splash-line"></div>
              <div class="widget-body text-center">
                <h4>WELCOME TO EXCELERATE</h4>
                <h3>STEP 2 OUT OF 2</h3>
                <p>Verify that your information is correct or add it to your profile.</p>
                <form @submit.prevent="submitOnboard">
                  <div class="form-group text-left">
                    <label>First name</label>
                    <input
                      type="text"
                      v-model="firstName"
                      name="firstName"
                      class="form-control form-control-lg"
                      autofocus
                      required
                    />
                  </div>
                  <div class="form-group text-left">
                    <label>Last name</label>
                    <input
                      type="text"
                      v-model="lastName"
                      name="lastName"
                      class="form-control form-control-lg"
                      autofocus
                      required
                    />
                  </div>
                  <div class="form-group text-left">
                    <label>Phone number</label>
                    <input
                      type="text"
                      v-model="phoneNumber"
                      name="phoneNumber"
                      class="form-control form-control-lg"
                      placeholder="e.g. 12345678"
                      autofocus
                      required
                    />
                  </div>
                  <div class="form-group text-left">
                    <label>What is your current position in the company?</label>
                    <select class="form-control" v-model="userRole">
                      <option v-for="(role, index) in designationList" :key="index" :value="role.id" :selected="(role == userRole)">{{role.designation}}</option>
                    </select>
                  </div>
                  <div class="form-group text-left">
                    <label>Password</label>
                    <input
                      type="password"
                      v-model="password"
                      class="form-control form-control-lg"
                      name="new-password"
                      autocomplete="new-password"
                      placeholder="Please enter a desired password"
                      required
                    />
                  </div>
                  <div class="form-group text-left">
                    <label>Confirm password</label>
                    <input
                      type="password"
                      v-model="confirmPassword"
                      placeholder="Please confirm your password"
                      :class="
                          confirmPassword.length > 0 &&
                          confirmPassword !== password
                            ? 'form-control  form-control-lg is-invalid'
                            : 'form-control  form-control-lg'
                        "
                      required
                    />
                  </div>
                  <div
                    class="alert alert-info"
                    v-if="passwordMessage.length > 0"
                  >{{ passwordMessage }}</div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox mb-1">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        name="role"
                        id="signupTerms"
                        v-model="terms"
                        value="terms"
                        required
                      />
                      <label class="custom-control-label" for="signupTerms">
                        I accept
                        <router-link to="/user/terms" class="color-third">the terms and conditions</router-link>
                      </label>
                    </div>
                  </div>
                  <div class="form-group text-left">
                    <button class="btn btn-primary btn-block btn-lg">Next</button>
                  </div>
                </form>
              </div>
            </div>
            <!-- /widget -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      loading: false,
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      passwordMessage: "",
      terms: false,
      userRole: null,
      // firstName: this.$store.getters.getFirstName,
      // lastName: this.$store.getters.getLastName,
    };
  },
  mounted: function(){
    this.$store.dispatch("getDesignationList")
  },
  computed: {
    designationList(){
      return this.$store.getters.designationList
    },
    user_id: function(){
      return this.$store.getters.onboardingData
    },
  },
  methods: {
    submitOnboard(){
      var vm = this;
      const data = {
        password: vm.password,
        confirm_password: vm.confirmPassword,
        phoneNumber: vm.phoneNumber,
        language: 'en',
        curr_role: vm.userRole,
        firstName: vm.firstName,
        lastName: vm.lastName,
        user_id: vm.user_id.id,
        docId: vm.$store.getters.userId
      }
      this.$store.dispatch('completeOnboarding', data)
    }
  },
};
</script>

<style scoped>
#user-pages .welcome-flow-container .welcome-message {
  margin-top: 0px !important;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>